// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/Calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/Community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/MailingList.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-spiritual-growth-js": () => import("./../../../src/pages/SpiritualGrowth.js" /* webpackChunkName: "component---src-pages-spiritual-growth-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/Staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/Videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-posts-page-layout-js": () => import("./../../../src/templates/postsPageLayout.js" /* webpackChunkName: "component---src-templates-posts-page-layout-js" */),
  "component---src-templates-youtube-playlist-js": () => import("./../../../src/templates/YoutubePlaylist.js" /* webpackChunkName: "component---src-templates-youtube-playlist-js" */)
}

